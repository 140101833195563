/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    pre: "pre",
    code: "code",
    img: "img",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Since the moment ", React.createElement(_components.a, {
    href: "https://twitter.com/dhh/status/808348184481124352"
  }, "Rails embraced the JavaScript ecosystem"), ", it has quickly become super easy to be productive with JavaScript toolset. Productivity, the new ecosystem (often unfamiliar to Rails developers specialized in the back-end), and magic of Rails configuration made JS code a big, opaque box that “just works” and doesn't seem to need to be understood. Lack of understanding has consequences, one of which is bloated JavaScript bundle."), "\n", React.createElement(_components.p, null, "In this article, we'll create a Rails app with a simple React component using the most typical stack, analyze the bundle size and try to optimize two common reasons for size inflation."), "\n", React.createElement(_components.h2, null, "Creating the project"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "rails new optimize_webpack_bundle_size --webpack=react\ncd optimize_webpack_bundle_size\nrails db:migrate\n")), "\n", React.createElement(_components.p, null, "While Rails with webpacker does support rendering React components out of the box, ", React.createElement(_components.a, {
    href: "https://github.com/reactjs/react-rails"
  }, "react-rails"), " gem provides additional helpers, generators, and elegant hooks. Let's add it to the ", React.createElement(_components.code, null, "Gemfile"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "gem 'react-rails'\n")), "\n", React.createElement(_components.p, null, "Next, install the gem:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "bundle install\nrails generate react:install\n")), "\n", React.createElement(_components.p, null, "Finally, clean up the project by deleting ", React.createElement(_components.code, null, "app/javascript/packs/hello_react.jsx"), " file and removing ", React.createElement(_components.code, null, "console.log('Hello World from Webpacker')"), " line from ", React.createElement(_components.code, null, "app/javascript/packs/application.js"), "."), "\n", React.createElement(_components.h2, null, "Generating React component"), "\n", React.createElement(_components.p, null, "We're going to implement a component that displays match results. It will receive match date and score, and will format the date in relative format and pick the winning team."), "\n", React.createElement(_components.p, null, "To make sense of the purpose of this tutorial, let's use external dependencies to pick the winning team (", React.createElement(_components.code, null, "lodash"), ") and format the relative date (", React.createElement(_components.code, null, "moment"), ") to inflate our JS bundle a little bit:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "yarn add moment lodash\n")), "\n", React.createElement(_components.p, null, "Time to generate the component:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "rails g react:component MatchResults date:string teamScores:array\nmv app/javascript/components/MatchResults.js app/javascript/components/MatchResults.jsx\n")), "\n", React.createElement(_components.p, null, "Our component will be so simple it will not contain any state – so it makes the most sense to implement it as a functional stateless component: not a class but a function taking just one argument: props. Since we already know which properties the component will use, we can destructure the argument."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "import React from \"react\";\nimport PropTypes from \"prop-types\";\nimport _ from \"lodash\";\nimport moment from \"moment\";\n\nexport default function MatchResults({ date, teamScores }) {\n  const relativeDateDescription = moment(new Date(date)).fromNow();\n  const winningTeam = _.maxBy(teamScores, (t) => t.score);\n\n  return (\n    <p>\n      {winningTeam.name} won the match {relativeDateDescription}, scoring{\" \"}\n      {winningTeam.score} frags.\n    </p>\n  );\n}\n\nMatchResults.propTypes = {\n  date: PropTypes.string.isRequired,\n  teamScores: PropTypes.arrayOf(\n    PropTypes.shape({\n      name: PropTypes.string.isRequired,\n      score: PropTypes.number.isRequired,\n    }),\n  ).isRequired,\n};\n")), "\n", React.createElement(_components.p, null, "Finally, we have to generate a controller to display our component:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "rails g controller pages index\n")), "\n", React.createElement(_components.p, null, "Here's how ", React.createElement(_components.code, null, "app/views/pages/index/html.erb"), " will look like:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-erb"
  }, "<%= react_component \"MatchResults\", {\n    date: 96.hours.ago.to_date,\n    teamScores: [\n      {name: \"Blue Team\", score: 2},\n      {name: \"Red Team\", score: 3}\n    ]\n} %>\n")), "\n", React.createElement(_components.p, null, "Remember to plug this view to ", React.createElement(_components.code, null, "config/routes.rb"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-ruby"
  }, "Rails.application.routes.draw do\n  root to: 'pages#index'\nend\n")), "\n", React.createElement(_components.p, null, "Also, include the JS pack in application layout:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-erb"
  }, "<head>\n  ...\n  <%= javascript_pack_tag 'application', 'data-turbolinks-track': 'reload' %>\n</head>\n")), "\n", React.createElement(_components.p, null, "Our app is ready to run:"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "./images/1.png",
    alt: "MatchResult component in the browser"
  })), "\n", React.createElement(_components.p, null, "That's a pretty unimpressive, hello-world-like app with two small libraries. Hopefully, the JS bundle ends up being relatively small. Let's check it out."), "\n", React.createElement(_components.h2, null, "Visualizing the JavaScript dependencies"), "\n", React.createElement(_components.p, null, "Webpack has built-in size profiler and can provide a JSON file with all information we need:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "node_modules/.bin/webpack --config config/webpack/production.js --profile --json\n")), "\n", React.createElement(_components.p, null, "Unfortunately, the resulting file is huge, and it's impossible to analyze it manually. That's why it makes the most sense to visualize this data. We'll do it using ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/webpack-bundle-analyzer"
  }, "webpack-bundle-analyzer"), ":"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "yarn add webpack-bundle-analyzer --dev\n")), "\n", React.createElement(_components.p, null, "Now we can generate stats file and point it to the analyzer:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "node_modules/.bin/webpack --config config/webpack/production.js --profile --json > stats.json\nnode_modules/.bin/webpack-bundle-analyzer stats.json\n")), "\n", React.createElement(_components.p, null, "This operation will open the web browser with a simple application allowing you to analyze the JavaScript dependencies visually. Feel free to scroll and zoom in for greater detail!"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "./images/2.png",
    alt: "Visualization of unoptimized JS bundle"
  })), "\n", React.createElement(_components.p, null, "Visualizing the dependencies made it evident that ", React.createElement(_components.code, null, "moment"), " and ", React.createElement(_components.code, null, "loads"), " are the main reasons for JS bloat in our simple app. Bundle analyzer states that stat size of application.js is 1.17 MB. Let's try to optimize it."), "\n", React.createElement(_components.h2, null, "Optimizing moment.js size"), "\n", React.createElement(_components.p, null, "It's quite apparent that locales are responsible for the bloat. Webpack did its best to optimize the bundle size by ", React.createElement(_components.a, {
    href: "https://webpack.js.org/guides/tree-shaking/"
  }, "tree shaking"), ", but it works only for libraries implemented using ES6 modules. Moment.js was written before the concept of ES6 modules even existed so it's no wonder that it doesn't make good use of it and as a result, it eagerly includes all the locales when we import it. If you're looking for a modern, modular, ES6 alternative, try ", React.createElement(_components.a, {
    href: "https://date-fns.org"
  }, "date-fns"), "."), "\n", React.createElement(_components.p, null, "There's not much we can do in our code to optimize the way we import ", React.createElement(_components.code, null, "moment"), ", but Webpack is powerful enough to allow us to ignore specific/all locales. There are two ways to do it."), "\n", React.createElement(_components.h3, null, "Using IgnorePlugin"), "\n", React.createElement(_components.p, null, "Edit ", React.createElement(_components.code, null, "config/webpack/environment.js"), " and make it look like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const { environment } = require(\"@rails/webpacker\");\n\nconst webpack = require(\"webpack\");\n\nenvironment.plugins.prepend(\n  \"MomentIgnoreLocales\",\n  new webpack.IgnorePlugin(/^\\.\\/locale$/, /moment$/),\n);\n\nmodule.exports = environment;\n")), "\n", React.createElement(_components.p, null, "Down to 814 KB, that's about 300 KB smaller. Nice!"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "./images/3.png",
    alt: "Visualization of JS bundle with optimized Moment.js locale imports"
  })), "\n", React.createElement(_components.p, null, "With this solution, you can still import the locale you'd like to use. However, you need to do it explicitly, as the locale is no longer loaded automatically. Not ideal, let's proceed to the second solution:"), "\n", React.createElement(_components.h3, null, "Using ContextReplacementPlugin"), "\n", React.createElement(_components.p, null, "Edit ", React.createElement(_components.code, null, "config/webpack/environment.js"), " and make it look like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const { environment } = require(\"@rails/webpacker\");\nconst webpack = require(\"webpack\");\n\nenvironment.plugins.prepend(\n  \"MomentContextReplacement\",\n  new webpack.ContextReplacementPlugin(/moment[\\/\\\\]locale$/, /en|pl/),\n);\n\nmodule.exports = environment;\n")), "\n", React.createElement(_components.p, null, "This method results in a similar drop in size but you can additionally specify which locales you want to use up-front, so you don't need to worry about it when importing and using the library."), "\n", React.createElement(_components.h2, null, "Optimizing lodash.js size"), "\n", React.createElement(_components.p, null, "Now ", React.createElement(_components.code, null, "lodash"), " is the only obviously standing out library that needs our attention. Unlike ", React.createElement(_components.code, null, "moment.js"), ", ", React.createElement(_components.code, null, "lodash"), " is written in a modular way. If we import only the functions we use (instead of ", React.createElement(_components.code, null, "_"), " object that contains all functions), Webpack will optimize the bundle and make sure to include only the necessary parts of the library."), "\n", React.createElement(_components.h3, null, "Importing specific functions"), "\n", React.createElement(_components.p, null, "We could optimize imports in our component so that it could look like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import maxBy from \"lodash/maxBy\";\n// ...\nconst winningTeam = maxBy(teamScores, (t) => t.score);\n")), "\n", React.createElement(_components.p, null, "This simple operation yielded an amazing improvement: our JS bundle size is down to 396 KB!"), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "./images/4.png",
    alt: "Visualization of JS bundle with optimized Moment.js and Lodash.js"
  })), "\n", React.createElement(_components.h3, null, "Babel plugin"), "\n", React.createElement(_components.p, null, "Since this is a toy project, fixing ", React.createElement(_components.code, null, "lodash"), " imports was relatively quick, but a massive project with hundreds of React components does not have this luxury. Fortunately, we can use ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/babel-plugin-lodash"
  }, "babel-plugin-lodash"), " to let Babel automatically transform our imports."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "yarn add babel-plugin-lodash\n")), "\n", React.createElement(_components.p, null, "After adding the plugin, all we have to do is declare our intention to use it in ", React.createElement(_components.code, null, ".babelrc"), " file in the project:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  // ...\n  \"plugins\": [\n    \"lodash\",\n    \"syntax-dynamic-import\",\n    \"transform-object-rest-spread\",\n\t  // ...\n  ]\n}\n")), "\n", React.createElement(_components.p, null, "That's it! The stats will look identical, but we don't have to modify a single line of JavaScript. Letting tools do the heavy lifting is always the preferred option."), "\n", React.createElement(_components.p, null, "As a side note, ", React.createElement(_components.a, {
    href: "https://www.npmjs.com/package/lodash-es"
  }, "lodash-es"), " library enables more elegant ES6 imports but you'll still need to hand-pick the functions you'd like to use."), "\n", React.createElement(_components.h2, null, "Wrapping up"), "\n", React.createElement(_components.p, null, "Tuning Webpack and Babel configuration resulted in JS bundle size dropping from 1.17 MB to 396 KB without changing a single line of code. That's an incredible improvement."), "\n", React.createElement(_components.p, null, "To make sure the process is repeatable and documented, I recommend adding a script to ", React.createElement(_components.code, null, "package.json"), " file:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  \"dependencies\": {\n    // ...\n  },\n  \"devDependencies\": {\n\t  // ....\n  },\n  \"scripts\": {\n    \"webpack:analyze\": \"mkdir -p public/packs && node_modules/.bin/webpack --config config/webpack/production.js --profile --json > public/packs/stats.json && node_modules/.bin/webpack-bundle-analyzer public/packs/stats.json\"\n  }\n}\n")), "\n", React.createElement(_components.p, null, "Now you can easily generate and view the stats by running ", React.createElement(_components.code, null, "yarn run webpack:analyze"), ". Happy tuning!"), "\n", React.createElement(_components.p, null, "You can see the final result of this tutorial on ", React.createElement(_components.a, {
    href: "https://github.com/razorjack/optimize_webpack_bundle_size"
  }, "GitHub"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
